$bg-blue: #00a6d6;
$bg-blue-dark: #0a2644;

.btn-brand {
  background-color: $bg-blue !important;
  border-color: $bg-blue !important;
}

a,
.btn-link {
  color: $bg-blue;
}
